<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'SessionIndex'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.notifications')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list>
        <notification-item v-for="(item, key) in messages" :key="key" :item="item"/>
        <ion-item v-if="messages.length <= 0">
          <ion-label v-text="__('interface.no-results')"></ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage, IonRefresherContent, IonRefresher,
  loadingController
} from '@ionic/vue';
import {banOutline, checkmarkCircleOutline} from 'ionicons/icons';
import {API} from "@/utils/apiWrapper";
import NotificationItem from '@/components/NotificationItem';
import {mapState} from "vuex";

export default {
  name: "NotificationsIndex",
  components: {
    NotificationItem,
    IonPage,
    IonRefresherContent,
    IonRefresher
  },
  setup() {
    return {banOutline, checkmarkCircleOutline}
  },
  computed: {
    showRhrNotification() {
      return this.staticNotifications.rhr ?? false
    },
    ...mapState({
      staticNotifications: state => state.staticNotifications
    })
  },
  data() {
    return {
      selectedSegment: 'messages',
      messages: [],
      invitations: []
    }
  },
  methods: {
    doRefresh(event) {
      this.loadData().finally(() => {
        event.target.complete()
      })
    },
    async loadData() {
      return API.get('TheSpottApi', '/notifications', {}).then(response => {
        this.messages = response.data ?? [];
      })
    }
  },
  async ionViewDidEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()

    this.loadData().finally(() => {
      loading.dismiss()
    })
  }
}
</script>
