<template>
    <ion-item @click="actionPreview">
        <ion-label>
            <h2 v-text="item.title"></h2>
            <p v-text="item.content"></p>
        </ion-label>
        <ion-chip v-if="!item.is_read" color="primary">{{ __('interface.new') }}</ion-chip>
    </ion-item>
</template>

<script>
    import {alertController} from "@ionic/vue";

    export default {
        name: "NotificationItem",
        props: {
            item: {
                type: Object,
                required: true,
            }
        },
        methods: {
            async actionPreview() {
                const alert = await alertController
                    .create({
                        header: this.item.title,
                        message: this.item.content,
                        buttons: [this.__('interface.close')],
                    });
                return alert.present();
            }
        }
    }
</script>

<style scoped>

</style>